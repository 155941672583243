import React from 'react'
import './features.css'
import Feature from '../../components/feature/Feature';

const featuresData=[
  {
    title:'Improving end distrusts instantly' ,
    text:"Whether it's providing accurate information, resolving queries promptly, or delivering reliable recommendations, our AI is dedicated to enhancing trust and reliability in your digital experiences."
  } ,  
  {
    title:'Real-Time Feedback Analysis' ,
    text:'Gain invaluable insights into customer sentiments and perceptions with our AI-driven feedback analysis.'
  } , 
   {
    title:'Automated Customer Support' ,
    text:'Instantly address customer queries and concerns, delivering timely solutions and enhancing overall satisfaction.'
  } , 
  {
    title:'Continuous Learning' ,
    text:'Our algorithms adapt and evolve over time, incorporating new data and insights to deliver increasingly sophisticated solutions that meet your changing needs.'
  } 

]

const Features = () => {
  return (
    <div className="gpt3__features section__padding" id="features">
      <div className="gpt3__features-heading">
        <h1 className="gradient__text">
        The Future is Now and You Just Need To Realize It. Step into Future Today & Make it Happen.
        </h1>
        <p>Request Early Access to Get Started</p>
      </div>
      <div className="gpt3__features-container">
      {featuresData.map((item,index)=> (
        <Feature  title={item.title} text={item.text} key={item.title + index}/>
      ))}
      </div>
    </div>
  )
}

export default Features