import React from 'react';
import Feature from '../../components/feature/Feature';
import './whatGPT3.css';

const WhatGPT3 = () => (
  <div className="gpt3__whatgpt3 section__margin" id="wgpt3">
    <div className="gpt3__whatgpt3-feature">
      <Feature title="What is GPT-3" text="Our AI companion harnesses the power of GPT-3 to provide unparalleled assistance and creativity. Whether you need writing support, problem-solving guidance, or engaging conversation, our AI is your ultimate ally, adapting to your needs and enhancing your productivity with its advanced natural language processing capabilities." />
    </div>
    <div className="gpt3__whatgpt3-heading">
      <h1 className="gradient__text">The possibilities are beyond your imagination</h1>
      <p>Explore the Library</p>
    </div>
    <div className="gpt3__whatgpt3-container">
      <Feature title="Chatbots" text="Experience seamless communication and support with our AI chatbot service. Our advanced natural language processing technology ensures fast, accurate responses to your queries, guiding you through your journey with unparalleled efficiency and convenience." />
      <Feature title="Knowledgebase" text="Unlock a treasure trove of knowledge with our AI-powered service. Seamlessly tap into vast databases and cutting-edge algorithms to access accurate and insightful information tailored to your needs, empowering you to make informed decisions and explore new horizons effortlessly." />
      <Feature title="Education" text="Elevate your learning experience with our AI-driven education service. Harnessing the power of advanced algorithms, personalized learning pathways, and interactive content, we empower learners to master new skills, explore diverse subjects, and achieve their educational goals with unprecedented efficiency and effectiveness." />
    </div>
  </div>
);

export default WhatGPT3;