import React from 'react'
import './possibility.css'
import possibilityImage from '../../assets/possibility.png'

const Possibility = () => {
  return (
    <div className="gpt3__possibility section__padding" id="possibility">
      <div className="gpt3__possibility-image">
        <img src={possibilityImage} alt="possibility" />
        </div>
        <div className="gpt3__possibility-content">
          <h4>Request Early Access to Get Started </h4>
          <h1 className="gradient__text">The possibilities are beyond your imagination</h1>
          <p>With our AI-powered solutions, unlock a realm of innovation and potential limited only by your creativity. From personalized experiences to predictive insights, harness the power of AI to redefine what's possible and shape the future on your terms.</p>
          <h4>Request Early Access to Get Started </h4>
        </div>
    </div>
  )
}

export default Possibility