import React from 'react'
import './header.css'
import people from '../../assets/people.png'
import ai from '../../assets/ai.png'

const Header = () => {
  return (
    <div className="gpt3__header section">
      <div className="gpt3__header-content">
        <h1 className="gradient__text">Let's Build Something amazing with GPT-3 OpenAI</h1>
        <p>Welcome to our AI-powered website where innovation meets intelligence! Whether you're seeking personalized recommendations, creative writing assistance, or deep insights, our cutting-edge GPT-3 technology is here to exceed your expectations. Simply input your query or task below, and let's embark on a journey of limitless possibilities together.</p>
        <div className="gpt3__header-content__input" >
          <input type="email" placeholder="Your Email Address" />
          <button type="button">Get Started</button>
        </div>
        <div className="gpt3__header-content__people">
          <img src={people} alt="people"/>
          <p>1,600 people requested access in the last 24 hours.</p>

        </div>
        
      </div>
      <div className="gpt3__header-image">
          <img src={ai} alt="ai" />

        </div>
    </div>
  )
}

export default Header